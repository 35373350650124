import React from 'react';
import { Divider } from 'antd';

import './VerticalDivider.less';

const VerticalDivider = () => (
  <Divider type="vertical" className="__VerticalDivider__" />
);

export default VerticalDivider;
